.customColumnCenter {
    justify-content: center;
    background: #ececf8;
}
.customColumnRight {
    justify-content: center;
    background: rgba(29, 77, 33, 0.09);
}

.customColumnTomada {
    justify-content: center;
    background: rgba(250, 133, 41, 0.75);
}
.customColumnTitle {
    background: rgba(250, 133, 41, 0.75);
}

.customColumnRestante{
    justify-content: center;
}
.customColumnRestanteFree {
    justify-content: center;
    background: rgba(5, 98, 13, 0.45);
}
.customColumnRestanteFreeTitle {
    background: rgba(5, 98, 13, 0.45);
}
.customColumnRestanteAll {
    justify-content: center;
    background: rgba(21, 101, 179, 0.45);
}
.customColumnRestanteAllTitle {
    background: rgba(21, 101, 179, 0.45);
}
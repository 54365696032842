.modal-line {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  border-top: 1.3px solid rgb(171, 177, 183);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
}

.multi-select {
  margin-left: 20px;
  margin-right: 20px;
}

.multi-label {
  margin-left: 40px;
  margin-right: 20px;
}

.multi-col {
  flex: 50%;
  text-align: center;
}

.div-audit {
  font-size: 10.5px;
}

.open-feed.inactiveBell svg[data-v-5b8e1db6] {
  fill: #e0e4e7;
  width: 20px;
}

div.detalle {
  height: 36px;
}

.k-grid td, .k-grid th {
  padding: 0.3rem;
}

.k-column-title {
  font-size: small;
}

.k-grid td, .k-grid th {

  font-size: small;
}

.k-dialog-titlebar {
  background-color: #093f70;
}


.k-window {
  border-color: #093f70;
}

.k-grid .k-indicator-container {

  font-size: small;
}

div.detalle {

  overflow: hidden;
  white-space: nowrap;
}


.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  color: #ffffff;
  background-color: #093f70;
}

.k-panelbar > .k-item > .k-link .k-icon, .k-panelbar > .k-item > .k-link .k-panelbar-item-icon, .k-panelbar > .k-panelbar-header > .k-link .k-icon, .k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
  color: #ffffff;
}

.k-panelbar > .k-item > .k-link:hover, .k-panelbar > .k-item > .k-link.k-state-hover, .k-panelbar > .k-panelbar-header > .k-link:hover, .k-panelbar > .k-panelbar-header > .k-link.k-state-hover {
  background-color: #093f70e0;
}

.k-grid td, .k-grid .k-grid-content, .k-grid .k-grid-header-locked, .k-grid .k-grid-content-locked {
  font-size: small;
}


.open-feed.inactiveBell svg[data-v-5b8e1db6] {
  fill: #fff !important;
}

.topnav .navbar-nav .nav-link {

  font-size: smaller !important;

}

.topnav .navbar-nav .dropdown.active > a {
  color: #b2b2b3 !important;

}

.open-feed.inactiveBell svg[data-v-5b8e1db6] {
  fill: #fff;
}

div div nofification-center div div svg {
  fill: #fff !important;
}

.k-dialog-titlebar {
  background-color: #093e6d;

}

.topnav .navbar-nav .nav-link {

  padding: 1rem 0.5rem;

}

.header-item {

  font-size: smaller;
}


.dropdown-menu {

  font-size: smaller;

}

.ellipsis-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.k-grid td.k-state-selected, .k-grid td.k-selected, .k-grid tr.k-state-selected > td, .k-grid tr.k-selected > td {
  background-color: rgba(9, 63, 112, .25) !important;
}

.k-pager-numbers .k-link.k-state-selected, .k-pager-numbers .k-link.k-selected {
  color: #093f70 !important;
  background-color: rgb(188, 199, 211) !important;
}


.k-panelbar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-item > .k-link.k-selected, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected, .k-panelbar > .k-panelbar-header > .k-link.k-selected {
  color: white;
  background-color: rgba(9, 63, 112, 1) !important;
}

.k-expander-header {
  padding: 0.4rem 1.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: smaller !important;
}

.k-expander + .k-expander.k-expanded, .k-expander.k-expanded + .k-expander {
  margin-top: 0 !important;
}

.padlock {
  font-size: small;
}

.matrix {
  position: relative;
  height: 100vh;
  background-color: #000;
}

.column {
  position: absolute;
  top: 0;

  &:nth-child(2n) {
    opacity: 0.4;
  }

  &:nth-child(1) {
    left: calc((6% - 50px));
  }
  &:nth-child(2) {
    left: calc((8% - 50px));
  }
  &:nth-child(3) {
    left: calc((10% - 50px));
  }
  &:nth-child(4) {
    left: calc((12% - 50px));
  }
  &:nth-child(5) {
    left: calc((14% - 50px));
  }
  &:nth-child(6) {
    left: calc((16% - 50px));
  }
  &:nth-child(7) {
    left: calc((18% - 50px));
  }
  &:nth-child(8) {
    left: calc((20% - 50px));
  }
  &:nth-child(9) {
    left: calc((22% - 50px));
  }
  &:nth-child(10) {
    left: calc((24% - 50px));
  }
  &:nth-child(11) {
    left: calc((26% - 50px));
  }
  &:nth-child(12) {
    left: calc((28% - 50px));
  }
  &:nth-child(13) {
    left: calc((30% - 50px));
  }
  &:nth-child(14) {
    left: calc((32% - 50px));
  }
  &:nth-child(15) {
    left: calc((34% - 50px));
  }
  &:nth-child(16) {
    left: calc((36% - 50px));
  }
  &:nth-child(17) {
    left: calc((38% - 50px));
  }
  &:nth-child(18) {
    left: calc((40% - 50px));
  }
  &:nth-child(19) {
    left: calc((42% - 50px));
  }
  &:nth-child(20) {
    left: calc((44% - 50px));
  }
  &:nth-child(21) {
    left: calc((46% - 50px));
  }
  &:nth-child(22) {
    left: calc((48% - 50px));
  }
  &:nth-child(23) {
    left: calc((50% - 50px));
  }
  &:nth-child(24) {
    left: calc((52% - 50px));
  }
  &:nth-child(25) {
    left: calc((54% - 50px));
  }
  &:nth-child(26) {
    left: calc((56% - 50px));
  }
  &:nth-child(27) {
    left: calc((58% - 50px));
  }
  &:nth-child(28) {
    left: calc((60% - 50px));
  }
  &:nth-child(29) {
    left: calc((62% - 50px));
  }
  &:nth-child(30) {
    left: calc((64% - 50px));
  }
  &:nth-child(31) {
    left: calc((66% - 50px));
  }
  &:nth-child(32) {
    left: calc((68% - 50px));
  }
  &:nth-child(33) {
    left: calc((70% - 50px));
  }
  &:nth-child(34) {
    left: calc((72% - 50px));
  }
  &:nth-child(35) {
    left: calc((74% - 50px));
  }
  &:nth-child(36) {
    left: calc((76% - 50px));
  }
  &:nth-child(37) {
    left: calc((78% - 50px));
  }
  &:nth-child(38) {
    left: calc((80% - 50px));
  }
  &:nth-child(39) {
    left: calc((82% - 50px));
  }
  &:nth-child(40) {
    left: calc((84% - 50px));
  }
  &:nth-child(41) {
    left: calc((86% - 50px));
  }
  &:nth-child(42) {
    left: calc((88% - 50px));
  }
  &:nth-child(43) {
    left: calc((90% - 50px));
  }
  &:nth-child(44) {
    left: calc((92% - 50px));
  }
  &:nth-child(45) {
    left: calc((94% - 50px));
  }
  &:nth-child(46) {
    left: calc((96% - 50px));
  }
  &:nth-child(47) {
    left: calc((98% - 50px));
  }
}

.letter-container {
  position: absolute;
  top: 0;
  left: 0;
  animation: fall 20s linear infinite;
  
  &:nth-child(1) {
    animation-delay: 0.2s;
  }
  &:nth-child(2) {
    animation-delay: 0.8s;
  }
  &:nth-child(3) {
    animation-delay: 1.4s;
  }
  &:nth-child(4) {
    animation-delay: 2s;
  }
  &:nth-child(5) {
    animation-delay: 2.6s;
  }
  &:nth-child(6) {
    animation-delay: 3.2s;
  }
  &:nth-child(7) {
    animation-delay: 3.8s;
  }
  &:nth-child(8) {
    animation-delay: 4.4s;
  }
  &:nth-child(9) {
    animation-delay: 5s;
  }
  &:nth-child(10) {
    animation-delay: 5.6s;
  }
  &:nth-child(11) {
    animation-delay: 6.2s;
  }
  &:nth-child(12) {
    animation-delay: 6.8s;
  }
  &:nth-child(13) {
    animation-delay: 7.4s;
  }
  &:nth-child(14) {
    animation-delay: 8s;
  }
  &:nth-child(15) {
    animation-delay: 8.6s;
  }
  &:nth-child(16) {
    animation-delay: 9.2s;
  }
  &:nth-child(17) {
    animation-delay: 9.8s;
  }
  &:nth-child(18) {
    animation-delay: 10.4s;
  }
  &:nth-child(19) {
    animation-delay: 11s;
  }
  &:nth-child(20) {
    animation-delay: 11.6s;
  }
  &:nth-child(21) {
    animation-delay: 12.2s;
  }
  &:nth-child(22) {
    animation-delay: 12.8s;
  }
  &:nth-child(23) {
    animation-delay: 13.4s;
  }
  &:nth-child(24) {
    animation-delay: 14s;
  }
  &:nth-child(25) {
    animation-delay: 14.6s;
  }
  &:nth-child(26) {
    animation-delay: 15.2s;
  }
  &:nth-child(27) {
    animation-delay: 15.8s;
  }
  &:nth-child(28) {
    animation-delay: 16.2s;
  }
  &:nth-child(29) {
    animation-delay: 16.8s;
  }
  &:nth-child(30) {
    animation-delay: 17.2s;
  }
  &:nth-child(31) {
    animation-delay: 17.8s;
  }
}

.letter {
  font-family: "MatrixCodeNFI", monospace;
  font-size: 24px;
  color: #0f0;
}

@keyframes fall {
  0% {
    top: -100px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: calc(100vh + 100px);
    opacity: 0;
  }
}
